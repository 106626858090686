<template>
  <div>
    <v-app>
      <v-main class="main-background">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-background {
  background: #e71314;
  background: linear-gradient(90deg, #2eaf52 0%, #e71314 100%);
}
</style>
