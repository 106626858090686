import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  report_id: "",
};

const getters = {
  getReportid: (state) => state.report_id,
};

const mutations = {
  setReportid(state, payload) {
    state.report_id = payload;
  },
};

const actions = {
  fetchReports({ commit }, payload) {
    console.log("payloda", payload);
    const apiUrl = "report";
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },

  excelDownload({ commit }, payload) {
    console.log("payloda", payload, { responseType: "blob" });
    const apiUrl = "exportexcel";
    return private_url
      .post(apiUrl, payload, { responseType: "blob" })
      .then((response) => {
        return response;
      });
  },

  pdfDownload({ commit }, payload) {
    const apiUrl = "exportpdf";
    return private_url
      .post(apiUrl, payload, { responseType: "arraybuffer" }) // Use responseType: "arraybuffer" to receive binary data
      .then((response) => {
        return response.data; // Return the PDF data from the response
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
