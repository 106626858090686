import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  organizations: [],
  organizationid: "",
};
const getters = {
  allOrganizations: (state) => state.organizations,
  getorganizationid: (state) => state.organizationid,
};

const mutations = {
  setorganization(state, organizationdata) {
    state.organizations = organizationdata;
  },
  organizationAddition(state, organizationdata) {
    console.log("Organization payload", organizationdata);
    console.log("state ", state.organizations);
    state.organizations.push(organizationdata);
    console.log("after", state.organizations);
  },
  setOrganizationid(state, payload) {
    state.organizationid = payload;
  },
  // updateOrganization(state, { index, updateddataOrganization }) {
  //   // Replace the organization at the specified index with the updated organization
  //   state.organizations.splice(index, 1, updateddataOrganization);
  // },

  updateOrganization(state, { index, updatedOrganization }) {
    // Replace the organization at the specified index with the updated organization
    state.organizations[index] = updatedOrganization;
  },

  delOrganization(state, organizationid) {
    state.organizations = state.organizations.filter(
      (organization) => organizationid !== organization.user_id
    );
  },
};

const actions = {
  fetchOrganizations({ commit }) {
    const apiUrl = "organization";
    return private_url.get(apiUrl).then((response) => {
      commit("setorganization", response.data);
      return response;
    });
  },

  addOrganization({ commit }, payload) {
    const apiUrl = "organization";
    return private_url.post(apiUrl, payload).then((response) => {
      commit("organizationAddition", response.data.data);
      return response;
    });
  },

  showOrganization({ commit }, organizationid) {
    const apiUrl = `organization/${organizationid}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },

  // editOrganization({ state, commit }, payload) {
  //   console.log("paylod", payload);
  //   const apiUrl = "organization/edit";
  //   return private_url.post(apiUrl, payload).then((response) => {
  //     const updatedOrganization = response.data.data;
  //     console.log("updatedOrganization", updatedOrganization.user_id);
  //     const updatedOrganizationIndex = state.organizations.findIndex(
  //       (organization) => organization.user_id === updatedOrganization.user_id
  //     );

  //     if (updatedOrganizationIndex !== -1) {
  //       commit("updateOrganization", {
  //         index: updatedOrganizationIndex,
  //         updatedOrganization,
  //       });
  //     }

  //     console.log("Updated state.organizations", state.organizations);
  //     return response;

  //   });
  // },
  editOrganization({ state, commit }, payload) {
    console.log("payload edit", payload);
    const apiUrl = "organization/edit";
    return private_url.post(apiUrl, payload).then((response) => {
      console.log("updatedOrganization", response.data.data.user_id);
      const updatedOrganization = response.data.data;
      const updatedOrganizationIndex = state.organizations.findIndex(
        (organization) => organization.user_id === updatedOrganization.user_id
      );
      if (updatedOrganizationIndex !== -1) {
        commit("updateOrganization", {
          index: updatedOrganizationIndex,
          updatedOrganization,
        });
      }
      console.log("Updated state.organizations", state.organizations);
      return response;
    });
  },

  deleteOrganization({ commit }, organizationid) {
    const apiUrl = `organization/${organizationid}`;
    return private_url.delete(apiUrl).then((response) => {
      commit("delOrganization", organizationid);
      return response;
    });
  },

  inviteOrganization({ commit }, payload) {
    const apiUrl = `organization/urlgenerate`;
    return private_url.post(apiUrl, payload).then((response) => {
      console.log("response data", response.data);
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
