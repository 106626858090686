import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  userData: {},
  token: "",
  dashboardPermission: {},
  categoryPermission: {},
  subcategoryPermission: {},
  postPermission: {},
  wingPermission: {},
  organizationPermission: {},
  reportsPermission: {},
  rolesPermission: {},
};

const getters = {
  getToken: (state) => state.token,
  getdataUser: (state) => state.userData,
  getdashboardPermission: (state) => state.dashboardPermission,
  getcategoryPermission: (state) => state.categoryPermission,
  getsubcategoryPermission: (state) => state.subcategoryPermission,
  getpostpermission: (state) => state.postPermission,
  getwingpermission: (state) => state.wingPermission,
  getrolePermission: (state) => state.rolesPermission,
  getorganizationPermission: (state) => state.organizationPermission,
  getreportPermission: (state) => state.reportsPermission,
};
const mutations = {
  addToken(state, token) {
    state.token = token;
  },
  setuserdata(state, userdata) {
    state.userData = userdata;
  },
  setPermissions(state, permissions) {
    permissions.forEach((permission) => {
      const { menu } = permission;

      // Assign the permission to the corresponding state property based on the menu
      if (menu === "dashboard") {
        state.dashboardPermission = permission;
      } else if (menu === "category") {
        state.categoryPermission = permission;
      } else if (menu === "subcategory") {
        state.subcategoryPermission = permission;
      } else if (menu === "post") {
        state.postPermission = permission;
      } else if (menu === "wing") {
        state.wingPermission = permission;
      } else if (menu === "organization") {
        state.organizationPermission = permission;
      } else if (menu === "report") {
        state.reportsPermission = permission;
      } else if (menu === "role") {
        state.rolesPermission = permission;
      }
    });
  },
};
const actions = {
  login({ state, commit }, payload) {
    console.log(payload);
    const apiUrl = "login";
    return public_url.post(apiUrl, payload).then((response) => {
      console.log("res", response.data);
      commit("addToken", response.data["token"]);
      const permissions = response.data["permissions"];
      commit("setPermissions", permissions);
      commit("setuserdata", response.data["user"]);
      console.log("state user", state.userData);
      return response;
    });
  },
  urlCheck({ commit }, payload) {
    console.log("payload", payload);
    const apiUrl = "decrypt";
    return public_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
