<template>
  <div>
    <div>
      <v-row class="mt-4">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <div class="d-flex flex-column align-center">
            <div>
              <div class="text-center">
                <img
                  src="../assets/logo.png"
                  width="200px"
                  height="80px"
                  class="mt-1"
                />
              </div>
              <h2 class="text-center font-weight-medium mb-1 mt-6">
                Register Organization
              </h2>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-4">
        <v-col cols="12" sm="12" md="8" lg="8" xl="8" class="mx-auto my-auto">
          <v-card class="elevation-1">
            <v-form ref="form">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      label="Name"
                      v-model="organizationData.userName"
                      :rules="UserNameRules"
                      filled
                      readonly
                      disabled
                      prepend-inner-icon="mdi-account-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      label="Organization Name"
                      append-icon=""
                      v-model="organizationData.Organization"
                      :rules="OrganizationNameRules"
                      filled
                      clearable
                      prepend-inner-icon="mdi-chart-scatter-plot-hexbin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                      :items="genderlist"
                      v-model="organizationData.Gender"
                      :rules="GenderRules"
                      filled
                      clearable
                      label="Gender"
                      prepend-inner-icon="mdi-gender-male-female"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="CNIC"
                      v-model="organizationData.Cnic"
                      filled
                      type="number"
                      prepend-inner-icon="mdi-card-account-details-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="WhatsApp Number"
                      v-model="organizationData.whatsappno"
                      :rules="WhatsRules"
                      filled
                      clearable
                      readonly
                      disabled
                      type="number"
                      prepend-inner-icon="mdi-whatsapp"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field
                      label="Password"
                      v-model="organizationData.userPassword"
                      :rules="PasswordRules"
                      filled
                      clearable
                      prepend-inner-icon="mdi-lock"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <!-- <v-select
                    :items="allCategory"
                    item-text="categoryname"
                    item-value="categoryid"
                    v-model="organizationData.categoryid"
                    filled
                    clearable
                    prepend-inner-icon="mdi-weather-tornado"
                    label="Category"
                  ></v-select> -->
                    <v-autocomplete
                      label="Category"
                      :items="allCategory"
                      item-text="categoryname"
                      item-value="categoryid"
                      :rules="CatetegoryRules"
                      v-model="organizationData.categoryid"
                      filled
                      disabled
                      prepend-inner-icon="mdi-weather-tornado"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <!-- <v-select
                    :items="allRoles"
                    item-text="role_name"
                    item-value="role_id"
                    v-model="organizationData.role_id"
                    filled
                    clearable
                     prepend-inner-icon="mdi-order-bool-descending-variant"
                    label="Role"
                  ></v-select> -->
                    <v-autocomplete
                      label="Role"
                      :items="allRoles"
                      item-text="role_name"
                      item-value="role_id"
                      v-model="organizationData.role_id"
                      :rules="RoleRules"
                      filled
                      disabled
                      prepend-inner-icon="mdi-order-bool-descending-variant"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <!-- <v-select
                    :items="allPost"
                    item-text="designation"
                    item-value="postid"
                    v-model="organizationData.postid"
                    filled
                    clearable
                    label="Post"
                    prepend-inner-icon="mdi-post-outline"
                  ></v-select> -->
                    <v-autocomplete
                      label="Post"
                      :items="allPost"
                      item-text="designation"
                      item-value="postid"
                      v-model="organizationData.postid"
                      :rules="PostRules"
                      filled
                      clearable
                      prepend-inner-icon="mdi-post-outline"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <!-- <v-select
                    :items="allWing"
                    item-text="wingname"
                    item-value="wingid"
                    v-model="organizationData.wingid"
                    filled
                    clearable
                    label="Wings"
                    prepend-inner-icon="mdi-wind-power-outline"
                  ></v-select> -->
                    <v-autocomplete
                      label="Wings"
                      :items="allWing"
                      :rules="WingRules"
                      item-text="wingname"
                      item-value="wingid"
                      v-model="organizationData.wingid"
                      filled
                      clearable
                      prepend-inner-icon="mdi-wind-power-outline"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-file-input label="Attachment" filled></v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  class="ml-3 mb-2 text-capitalize"
                  @click="Organizationadd"
                >
                  <v-icon left>mdi-chart-scatter-plot-hexbin</v-icon> Register
                  Organization</v-btn
                >
                <v-btn
                  color="white error--text"
                  class="ml-3 mb-2 text-capitalize"
                  to="/login"
                  ><v-icon left> mdi-cancel</v-icon> Cancel</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <template>
        <div class="text-center ma-2">
          <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ text }}

            <template v-slot:actions>
              <v-btn color="red" variant="text" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      snackbar: false,
      text: "",
      timeout: 2000,
      color: "",

      param1: "",
      param2: "",
      param3: "",
      param4: "",
      genderlist: ["Male", "Female"],
      allRoles: [],
      allCategory: [],
      allPost: [],
      allWing: [],
      organizationData: {
        userName: "",
        Organization: "",
        Gender: "",
        Cnic: "",
        contactno: "",
        whatsappno: "",
        userPassword: "",
        categoryid: "",
        role_id: "",
        postid: "",
        wingid: "45661d49-03f9-4345-8f2b-94ca56633a14",
        attachment: "",
      },
      RoleRules: [(v) => !!v || "Role is required"],
      PostRules: [(v) => !!v || "Post is required"],
      WingRules: [(v) => !!v || "Wing is required"],
      OrganizationNameRules: [(v) => !!v || "Organization Name is required"],
      GenderRules: [(v) => !!v || "Gender  is required"],
      UserNameRules: [(v) => !!v || "User Name is required"],
      CnicRules: [
        (v) => !!v || "CNIC Number is required",
        (v) => v.length === 13 || "Value must be 13 digits",
        (v) => (isNaN(v) ? "Value must be a number" : true),
      ],
      WhatsRules: [
        (v) => !!v || "whatsapp number is required",
        (v) => v.length === 11 || "Value must be 11 digits",
        (v) => (isNaN(v) ? "Value must be a number" : true),
      ],
      PasswordRules: [
        (v) =>
          /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(v) ||
          "Password must contain at least 8 characters, including at least one alphabet letter and one number",
      ],

      CatetegoryRules: [(v) => !!v || "Category Name is required"],
    };
  },
  mounted() {
    console.log("Component mounted");
  },
  methods: {
    ...mapActions("auth", ["urlCheck"]),
    ...mapActions("post", ["PostNameID"]),
    ...mapActions("wing", ["WingNameID"]),
    ...mapActions("organization", ["addOrganization"]),
    listPost() {
      this.PostNameID().then((response) => {
        this.allPost = response.data;
      });
    },
    listWing() {
      this.WingNameID().then((response) => {
        this.allWing = response.data;
      });
    },
    getParamData() {
      this.param1 = this.$route.params.param1;
    },
    checkParams() {
      const paramdata = {
        param1: this.param1,
      };
      this.urlCheck(paramdata).then((response) => {
        console.log("response: ", response.data);
        this.organizationData.userName = response.data.userName;
        this.organizationData.whatsappno = response.data.whatsappno;
        this.organizationData.role_id = response.data.role[0].role_id;
        this.organizationData.categoryid = response.data.category[0].categoryid;
        this.allRoles = response.data.role;
        this.allCategory = response.data.category;
      });
    },
    Organizationadd() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.addOrganization(this.organizationData)
          .then((response) => {
            this.snackbar = true;
            this.text = "Registered Successfully";
            this.$router.push("/login");
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              // Join the array of error messages into a single string
              const errorMessage = error.response.data.message;
              this.snackbar = true;
              this.text = errorMessage;
            } else {
              this.snackbar = true;
              this.text = "An error occurred.";
            }
          });
      }
    },
  },
  created() {
    this.getParamData();
    this.checkParams();
    this.listPost();
    this.listWing();
  },
};
</script>

<style scoped></style>
