import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  categories: [],
};

const getters = {
  categories: (state) => state.categories,
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  updateCategories(state, updatedCategories) {
    state.categories = [...updatedCategories];
  },

  deleteCategory(state, categoryid) {
    const deleteCategoryInTree = (categories) => {
      return categories.filter((category) => {
        if (category.categoryid === categoryid) {
          return false;
        }
        if (category.children && category.children.length > 0) {
          category.children = deleteCategoryInTree(category.children);
        }
        return true;
      });
    };

    state.categories = deleteCategoryInTree(state.categories);
  },

  addCategory(state, category) {
    state.categories.push(category);
  },

  addSubcategory(state, payload) {
    const { parentCategoryId, subcategory } = payload;

    const findCategoryAndAddSubcategory = (categories) => {
      for (const category of categories) {
        if (category.categoryid === parentCategoryId) {
          if (!category.children) {
            category.children = [];
          }
          category.children.push(subcategory);
          state.categories = [...state.categories];
          return true;
        }

        if (category.children && category.children.length > 0) {
          const found = findCategoryAndAddSubcategory(category.children);
          if (found) {
            return true;
          }
        }
      }
      return false;
    };

    if (findCategoryAndAddSubcategory(state.categories)) {
      console.log("categories", state.categories);
      console.log("Subcategory added successfully");
    } else {
      // Parent category not found
      console.error("Parent category not found");
    }
  },
};

const actions = {
  deleteCategory({ commit, state }, categoryId) {
    const apiUrl = `category/${categoryId}`;
    console.log("categoryIdstore", categoryId);

    return private_url.delete(apiUrl).then((response) => {
      commit("deleteCategory", categoryId);
      return response;
    });
  },

  editCategory({ state, commit }, payload) {
    const apiUrl = "category/edit";

    return private_url.post(apiUrl, payload).then((response) => {
      const updateCategoryInTree = (categories) => {
        for (const category of categories) {
          if (category.categoryid === payload.categoryid) {
            category.categoryname = payload.categoryName;
            category.description = payload.categoryDescription;
            break;
          }
          if (category.children && category.children.length > 0) {
            updateCategoryInTree(category.children);
          }
        }
      };

      updateCategoryInTree(state.categories);

      commit("updateCategories", state.categories);
      state.categories = [...state.categories];
    });
  },

  subCategoryAdd({ commit }, payload) {
    const apiUrl = "subcategory";
    return private_url.post(apiUrl, payload).then((response) => {
      const subcategory = response.data.data;
      const parentCategoryId = payload.parentid;
      commit("addSubcategory", { parentCategoryId, subcategory });
      return response;
    });
  },

  storeCategory({ commit }, payload) {
    const apiUrl = "category";
    return private_url.post(apiUrl, payload).then((response) => {
      commit("addCategory", response.data.data);
      return response;
    });
  },

  fetchCategories({ commit }) {
    const apiUrl = "UserCategory";

    return private_url
      .get(apiUrl)
      .then((response) => {
        // Update the categories in the store
        commit("setCategories", response.data);
        return response;
      })
      .catch((error) => {
        // Handle the error response
        console.error(error);
      });
  },

  CategoryNameID() {
    const apiUrl = "categoryName";
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
